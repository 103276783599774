<template>
  <div class="title">
    <div class="cn-title">{{ cnName }}</div>
    <div class="en-title">
      {{ enName }}
      <span class="leftLine"></span>
      <span class="rightLine"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Title",
  props: {
    cnName: String,
    enName: String,
  },
};
</script>
<style lang="less" scoped>
.title {
  margin: 0 auto;
  // width: 167px;
  height: 77px;
  padding-top: 1px;
  background: url("https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/title_bg.png")
    no-repeat center;
  background-size: contain;
  // overflow: hidden;
  text-align: center;
  .cn-title {
    margin-top: 22px;
    margin-bottom: 10px;
    height: 27px;
    font-size: 25px;
    font-family: FangSong;
    font-weight: bold;
    color: #262626;
  }
  .en-title {
    display: inline-block;
    zoom: 1;
    height: 12px;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #666666;
    position: relative;
    .leftLine {
      position: absolute;
      width: 24px;
      height: 1px;
      background: #b2806c;
      opacity: 0.76;
      top: 6px;
      left: -35px;
    }
    .rightLine {
      position: absolute;
      width: 24px;
      height: 1px;
      background: #b2806c;
      opacity: 0.76;
      top: 6px;
      right: -35px;
    }
  }
}
</style>
