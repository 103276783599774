<template>
  <div class="videobox">
    <img src="../assets/img/1617001828(1).jpg" class="bg" alt="" />
    <img src="../assets/img/1617001828(1).jpg" class="bg1" alt="" />
    <Nav />
    <div class="banner">
      <img :src="bannerUrl" alt="" />
    </div>
    <Title :cnName="cnName" :enName="enName" />
    <div class="common content">
      <div class="videoItem" v-for="item in list" :key="item.id">
        <div style="" class="boxItem">
          <div
            class="iconfont iconbofang play"
            @click="tovideo(item.vurl)"
          ></div>
          <img style="width:100%;" :src="item.url" alt="" />
        </div>
        <div class="title">{{ item.title }}</div>
        <div v-html="item.text" class="text"></div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="visible" @closed="videopause">
      <div style="text-align: center;">
        <video
          style="width:100%"
          :src="videoUrl"
          :autoplay="visible"
          :paused="!visible"
          controls
        ></video>
      </div>
    </el-dialog>
    <div class="footImg">
      <img
        src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/footer_video.png"
        alt=""
      />
      <!-- <div class="box">
        <div style="margin-top:45px;font-size: 24px;color: #262626;">
          生来古树茶
        </div>
        <div
          style="margin-top:10px;width:98px;height:1px;
background: #262626;"
          class="common"
        ></div>
        <div
          style="font-weight: bold;
color: #666666;
line-height: 36px;margin-top:35px;word-break: keep-all;width:234px"
          class="common"
        >
          无需加糖，天然甘甜， 甜蜜无负担
        </div>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Title from "../components/Title.vue";
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Title,
    Footer,
    Nav,
  },
  data() {
    return {
      bannerUrl:
        "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品视频1_01.png",
      cnName: "视频中心",
      enName: "PRODUCT VIDEO",
      visible: false,
      videoUrl: "",
      list: [
        {
          id: 1,
          title: "生来不凡",
          text: `<p>热带雨林 万物生长</p>
<p> 百年岁月 攀爬采摘</p> 
<p>这是 生来不凡的茶</p>
`,
          url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/video1.jpg",
          vurl:
            "https://shenglai.oss-cn-shenzhen.aliyuncs.com/video/%E7%94%9F%E6%9D%A5%E4%B8%8D%E5%87%A1.mp4",
        },
        {
          id: 2,
          title: "生来勇敢",
          text: `<p>片片采摘 古法制作</p>
<p>十道工序 守住初心</p>
<p>这是 生来勇敢的茶</p>
`,
          url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/video2.jpg",
          vurl:
            "https://shenglai.oss-cn-shenzhen.aliyuncs.com/video/%E7%94%9F%E6%9D%A5%E5%8B%87%E6%95%A2-1m3s_202132615205.mp4",
        },
        {
          id: 3,
          title: "生来为你",
          text: `<p>卓越品质 修养身心</p>
<p>温暖相伴 有滋有味</p>
<p>这是 生来为你的茶</p>
`,
          url: "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/video3.jpg",
          vurl:
            "https://shenglai.oss-cn-shenzhen.aliyuncs.com/video/%E7%94%9F%E6%9D%A5%E4%B8%BA%E4%BD%A0-1m6s_2021326153646.mp4",
        },
      ],
    };
  },
  methods: {
    tovideo(url) {
      this.videoUrl = url;
      this.visible = true;
      const video = document.querySelector("video");
      video.play();
    },
    videopause() {
      const video = document.querySelector("video");
      video.pause();
    },
  },
};
</script>

<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.videobox {
  position: relative;
  .bg {
    position: absolute;
    top: 842px;
    right: 0;
    width: 300px;
  }
  .bg1 {
    position: absolute;
    left: 0;
    bottom: 900px;
    width: 300px;
  }
}
.banner {
  width: 100%;
  height: 842px;
  background-size: 100% 842px;
  margin-bottom: 66px;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  margin-top: 40px;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .videoItem {
    width: 270px;
    margin-bottom: 60px;
    font-family: Source Han Serif SC;
    text-align: center;
    .boxItem {
      width: 270px;
      height: 270px;
      overflow: hidden;
      position: relative;
    }
    .boxItem img {
      transition: 1s;
    }
    .boxItem img:hover {
      transform: scale(1.1, 1.1);
    }
    .play {
      color: #fff;
      position: absolute;
      font-size: 70px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
    }
    .play:hover {
      cursor: pointer;
    }
    .title {
      margin-top: 13px;
      margin-bottom: 8px;
      // height: 22px;
      font-size: 20px;
      font-weight: bold;
      color: #262626;
    }
    .text {
      font-size: 16px;
      // font-weight: bold;
      color: #666666;
      line-height: 26px;
      word-break: keep-all;
    }
  }
}
.footImg {
  height: 550px;
  overflow: hidden;
  margin-top: 470px;
  margin-bottom: 50px;
  position: relative;
  img {
    width: 100%;
    height: 550px;
  }
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 370px;
    height: 230px;
    background: rgba(255, 254, 254, 0.85);
    text-align: center;
    font-family: Source Han Serif SC;
  }
}
</style>
